
import { HttpError, Services } from 'tradingmate-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'
import { PagedResultSet } from 'tradingmate-modules/src/models/api/common'
import QuoteRequest from 'tradingmate-modules/src/models/api/quotes/QuoteRequest'
import QuoteRequestFilter from 'tradingmate-modules/src/filters/QuoteRequestFilter'
import QuoteRequestCard from '@/components/cards/QuoteRequestCard.vue'
import Utils from 'tradingmate-modules/src/Utils'
import { Table, TableData, TableRow, TableHeader } from '@/components/table'
import Paging from '@/components/actions/Paging.vue'
import Badge from '@/components/status/Badge.vue'

@Component({
  components: {
    QuoteRequestCard,
    Table,
    TableRow,
    TableData,
    Paging,
    Badge,
    TableHeader
  }
})

export default class QuoteRequestsIndex extends Vue {
  private quoteRequests: PagedResultSet<QuoteRequest> | null = null;
  private httpError: HttpError | null = null;
  private isLoading = false

  private isoToDisplayDate = Utils.isoToDisplayDate

  private headings = ['Service Required', 'Name', 'Date', 'Status']

  mounted (): void {
    this.getQuotes()
  }

  getQuotes (): void {
    this.isLoading = true
    Services.API.Quotes.GetQuoteRequests(
      new QuoteRequestFilter({ BusinessBranchId: this.$store.state.identity.BusinessBranch.BusinessBranchId }))
      .then((returnData) => { this.quoteRequests = returnData })
      .catch((error) => { this.httpError = error })
      .finally(() => { this.isLoading = false })
  }
}
