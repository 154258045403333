
import { Vue, Component, Prop } from 'vue-property-decorator'
import QuoteRequest from 'tradingmate-modules/src/models/api/quotes/QuoteRequest'
import Utils from 'tradingmate-modules/src/Utils'

@Component
export default class NewPostCard extends Vue {
  @Prop({ required: true })
  private quoteRequest!: QuoteRequest

  private isoToDisplayDate = Utils.isoToDisplayDate
}
