import QuoteRequestStatus from '../models/api/quotes/QuoteReqeuestStatus'
import PagedFilterBase from "./PagedFilterBase";

export default class QuoteRequestFilter extends PagedFilterBase {
    BusinessBranchId?: string;
    Statuses: QuoteRequestStatus[] = [QuoteRequestStatus.Accepted, QuoteRequestStatus.Read, QuoteRequestStatus.Unread];
    FromDate?: string;
    ToDate?: string;
    FullName?: string;
    Email?: string;

    constructor(init?: Partial<QuoteRequestFilter>) {
        super()

        if (init) Object.assign(this, init);
    }
}
